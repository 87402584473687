
import { Component, Vue } from "nuxt-property-decorator";
import { ResponseNewsInterface, NewsModel } from "@/types/responses/news/ResponseNewsInterface";

@Component
export default class TheNews extends Vue {
  public news: Array<NewsModel> = [];
  public skeleton: Array<any> = [0, 1, 2, 3, 4, 5];

  async fetch() {
    try {
      const { data } = await this.$axios.get(this.$config.astaEndpoint + "news/get/6");

      this.news = (data as ResponseNewsInterface).data;
      this.newsItems();
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }

      throw e;
    }
  }
  fetchOnServer() {
    return false;
  }

  public newsItems() {
    let arr = [];
    for (const [key, value] of Object.entries(this.news)) {
      arr.push(value);
    }
    return arr;
  }

  public bg(index: number) {
    const bgs = ["bg-default", "bg-secondary", "bg-crediti", "bg-crediti", "bg-secondary", "bg-default"];

    return bgs[index];
  }

  public size(index: number) {
    return index === 0 || index === 5 ? "cardx2" : "cardx1";
  }

  public maxLength(str: string, max: number) {
    if (str.length < 100) {
      return str;
    }
    return str.slice(0, max) + " ...";
  }
}
